import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: 0,
      color: '#fff',
    },
}));

function Loading(props) {
  const styles = useStyles()

  return (
    <Backdrop open={props.open} className={styles.backdrop}>
      <CircularProgress />
    </Backdrop>
  )
}

export default Loading;
