// dropzone documentation: https://yuvaleros.github.io/material-ui-dropzone/
import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import BackupIcon from '@material-ui/icons/Backup';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import { DropzoneArea } from 'material-ui-dropzone';
import { API, Storage } from 'aws-amplify';
import { createFile } from '../../graphql/mutations';
import { fileCategories } from './FileUploadCategories';
import { useStyles } from './FileUploadStyles';
import { SchoolContext } from "../utils/Contexts";

function fileToDBSchema(file, s3Path, category, school, program) {
  /*
  id: ID!
  name: String
  size: Int
  item: String
  school: String
  category: String
  comment: String
  */
  return {
    name: file.name,
    size: file.size,
    item: s3Path,
    school: school,
    program: program,
    category: category
  }
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" size={80} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function FileUpload({open, setOpenState, addFilesToTable}) {
  const [ filesAdded, setFilesAdded ] = useState([]);
  const [ fileCategory, setFileCategory ] = useState("");
  const [ fileSchool, setFileSchool ] = useState("");
  const [ fileProgram, setFileProgram ] = useState("");
  const [ programs, setPrograms ] = useState([]);
  const [ uploadStart, setUploadStart ] = useState(false);
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const styles = useStyles();
  const schools = useContext(SchoolContext);
  const maxFileSize = 5242880;

  const customDropzoneIcon = () => (
    <BackupIcon className={`${styles.dropzone} ${styles.dropzoneIcon}`} />
  );

  const handleFileAdd = (addingfiles) => {
    setFilesAdded([...filesAdded, ...addingfiles]);
  }

  const clearFileUpload = () => {
    setFilesAdded([]);
    setFileCategory("");
    setFileSchool("");
    setFileProgram("");
    setPrograms([]);
    setUploadStart(false);
    setUploadProgress(0);
    setOpenState(false);
  }

  const handleFileUpload = async () => {
    setOpenState(!open);
    setUploadStart(!uploadStart);
    await Promise.all(filesAdded.map(async (file) => {
        await Storage.put(file.name, file, {
          progressCallback(progress) {
            setUploadProgress(progress.loaded / progress.total * 100);
          }
        });
        const s3Path = await Storage.get(file);
        const fileDBSchema = fileToDBSchema(file, s3Path, fileCategory, fileSchool, fileProgram);
        const apiRes = await API.graphql({ query: createFile,
                            variables: { input: fileDBSchema }});
        addFilesToTable(apiRes.data.createFile);
    }));
    clearFileUpload();
  }

  // const handleFileUpload = useEffect(()=>{
  //   fileUpload();
  // }, [])

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={() => setOpenState(!open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography
              variant="h5">
            Upload Files
          </Typography>
          <IconButton
            style={{right: '12px', top: '8px', position: 'absolute'}}
            onClick={() => clearFileUpload()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
          <TextField
            select
            label="File Category..."
            value={fileCategory}
            onChange={(event) => setFileCategory(event.target.value)}
            fullWidth
            variant="outlined"
          >
            {fileCategories.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="School..."
            value={fileSchool}
            onChange={(event) => {
              const chosenSchool = event.target.value;
              setFileSchool(chosenSchool);
              const matchedProgramSchools = schools.filter((school) => school.name === chosenSchool);
              setPrograms(matchedProgramSchools.map((school) => school.program));
            }}
            fullWidth
            variant="outlined"
          >
            {schools.map((school) => (
              <MenuItem key={school.name}
                        value={school.name}>
                {school.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Program..."
            value={fileProgram}
            onChange={(event) => setFileProgram(event.target.value)}
            fullWidth
            variant="outlined"
          >
            {programs.map((program) => (
              <MenuItem key={program}
                        value={program}>
                {program}
              </MenuItem>
            ))}
          </TextField>

          <DropzoneArea
            onChange={handleFileAdd}
            maxFileSize={maxFileSize}
            showPreviews={true}
            dropzoneClass={styles.dropzone}
            Icon={customDropzoneIcon}
            showPreviewsInDropzone={false}
            showFileNamesInPreview={true}
            useChipsForPreview
            previewGridProps={{container: { spacing: 1, direction: 'row' }}}
            previewChipProps={{classes: { root: styles.previewChip } }}
            dropzoneText="Click or Drop Files Here"
            previewText="Selected files"
            getDropRejectMessage={(file)=>(`File ${file.name} was rejected. The maximum size of a file is ${maxFileSize/1048576}MB`)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => clearFileUpload()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFileUpload} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={styles.backdrop} open={uploadStart}>
        <CircularProgressWithLabel color="inherit" value={uploadProgress} />
      </Backdrop>
    </>
  );
}

export default FileUpload;
