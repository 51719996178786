import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useStyles } from './DetailPanelStyles';

function DetailPanel({ rowData, handleRowUpdate }) {
  const classes = useStyles();
  const [isEditing, setEditingState] = useState(false);
  const [text, setText] = useState(rowData.description);

  const beforeEdit = () => {
    return (
      <>
        <Typography
          align='left'
          noWrap={true}
          paragraph={true}
          style={{
            whiteSpace: 'pre-wrap'
          }}
        >
          {text}
        </Typography>
        <Button
          variant="contained"
          size="large"
          className={clsx(classes.button, classes.editButton)}
          onClick={(e) => setEditingState(!isEditing)}
        >
          EDIT
        </Button>
      </>
    );
  }
  const detailPanelCancel = (e) => {
    setEditingState(!isEditing);
    setText(rowData.description);
  }

  const detailPanelUpdate = (e) => {
    e.preventDefault();

    const newData = {...rowData};
    newData.description = text;

    new Promise((resolve) => {
        handleRowUpdate(newData, rowData, resolve);
        setEditingState(!isEditing);
    }).catch((msg)=>{
      console.log(msg);
    });
  }

  const edit = () => {
    return (
      <>
      <form onSubmit={detailPanelUpdate}>
        <TextField
          multiline
          fullWidth
          defaultValue={text}
          variant="outlined"
          onChange={(e) => setText(e.target.value)}
        />
        <Button
          variant="contained"
          className={classes.button}
          onClick={detailPanelCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          className={classes.button}
          color="primary"
        >
          Submit
        </Button>
      </form>
      </>
    )
  }

  return (
      <Box
        className={classes.root}
      >
        {isEditing? edit() : beforeEdit()}
      </Box>
    )
}

export default DetailPanel;
