import React, { useState, useEffect } from 'react';
import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
// import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import Loading from '../utilsComponent/Loading';
import RequiredLabel from '../utilsComponent/RequiredLabel';
import { initialEducation,
         initialExperience } from './InitialForms';
import { API } from 'aws-amplify';
import { listEducations, listExperiences } from '../../graphql/queries';
import { createEducation, deleteEducation, updateEducation,
         createExperience, deleteExperience, updateExperience } from '../../graphql/mutations';

function BackgroundTab() {
  const [ background, setBackground ] = useState({});
  const [ educationOpen, setEducationOpen ] = useState(false);
  const [ experienceOpen, setExperienceOpen ] = useState(false);
  const [ education, setEducation ] = useState(initialEducation);
  const [ experience, setExperience ] = useState(initialExperience);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    fetchUserBackground();
  }, [])

  const fetchUserBackground = async () => {
    setLoading(true);
    try {
      const education = await API.graphql({ query: listEducations });
      const experience = await API.graphql({ query: listExperiences });
      setBackground({'education': education.data.listEducations.items,
                         'experience': experience.data.listExperiences.items});
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const handleEditEducation = (e) => {
    setEducation(e);
    setEducationOpen(!educationOpen);
    setIsEdit(true);
  }

  const handleAddOrCloseEducation = () => {
    setEducation(initialEducation);
    setEducationOpen(!educationOpen);
  }

  const handleEducationChange = (e) => {
    setEducation({...education, [e.target.name]: e.target.value});
    // educationValidation();
  }

  const educationValidation = () => {
    const valid = education.school.length > 0 &&
                  education.degree.length > 0 &&
                  education.major.length > 0 &&
                  education.gpa.length > 0 &&
                  education.gpaScale.length > 0 &&
                  education.startDate.length > 0 &&
                  education.endDate.length > 0;
    return !valid;
  }

  function educationDialog(){
    return (
      <Dialog
        open={educationOpen}
        fullWidth={true}
        onClose={handleAddOrCloseEducation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h5">
            Education
          </Typography>
          <IconButton
            style={{right: '12px', top: '8px', position: 'absolute'}}
            onClick={handleAddOrCloseEducation}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <form>
            <div className="form-group row">
                <label htmlFor="school" className="col-md-3 col-form-label"><strong>School</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           required
                           name="school"
                           placeholder="Full Name of School"
                           value={education.school}
                           onChange={handleEducationChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="degree" className="col-md-3 col-form-label"><strong>Degree</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="degree"
                           placeholder="Degree"
                           value={education.degree}
                           onChange={handleEducationChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="major" className="col-md-3 col-form-label"><strong>Major</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="major"
                           placeholder="Major"
                           value={education.major}
                           onChange={handleEducationChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="gpa" className="col-md-3 col-form-label"><strong>GPA</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="gpa"
                           placeholder="GPA"
                           value={education.gpa}
                           onChange={handleEducationChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="gpaScale" className="col-md-3 col-form-label"><strong>GPA Scale</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="gpaScale"
                           placeholder="GPA Scale"
                           value={education.gpaScale}
                           onChange={handleEducationChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="startDate" className="col-md-3 col-form-label"><strong>Start Date</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="startDate"
                           type='date'
                           placeholder="e.g. 2015-08-15"
                           value={education.startDate}
                           onChange={handleEducationChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="endDate" className="col-md-3 col-form-label"><strong>End Date (expected)</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="endDate"
                           type='date'
                           placeholder="e.g. 2019-05-02"
                           value={education.endDate}
                           onChange={handleEducationChange}
                    />
                </div>
            </div>

            <label htmlFor="description" className="col-form-label"><strong>Honors and Activities</strong></label>
            <p>1. Do you have honors or awards at school? Like Dean's List, scholarship, etc.
            If so, what are the requirements of getting such honors or awards? How did you get it?
            </p>
            <p>
            2. Do you have any impressive course project? If so, what is the class name?
            What did you do on that project? Why do you think the project is very impressive?
            (Please state the details)
            </p>
            <p>Please leave the TA, RA, or other activities in the experience section.</p>
            <textarea className="form-control"
                      name="description"
                      rows="8"
                      value={education.description}
                      onChange={handleEducationChange}
              >
              </textarea>
          </form>
        </DialogContent>
        <DialogActions>
          {
            isEdit ? <Button onClick={educationDelete}
                             color="primary"
                      >
              Delete
            </Button>:null
          }
          <Button onClick={handleAddOrCloseEducation} color="primary">
            Cancel
          </Button>
          <Button onClick={educationSubmit}
                  disabled={educationValidation()}
                  color="primary"
                  autoFocus >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  const educationDelete = async() => {
    try {
      const removeInput = {id: education.id}
      await API.graphql({ query: deleteEducation,
                          variables: { input: removeInput }});
      const newBackground = {...background, 'education': background.education.filter(edu => edu.id !== education.id)};
      setBackground(newBackground);
    } catch (e) {
      console.log(e);
    }
    setEducationOpen(!educationOpen);
    setEducation(initialEducation);
    setIsEdit(false);
  }

  const educationSubmit = async() => {
    setLoading(true);
    setEducationOpen(false);
    try {
      if (!isEdit){
        const apiRes = await API.graphql({ query: createEducation,
                            variables: { input: education}
                          });
        setBackground({...background, 'education': [...background.education, apiRes.data.createEducation]});
      } else {
        const { updatedAt, createdAt, owner, ...educationData } = education;
        await API.graphql({ query: updateEducation,
                            variables: { input: educationData }});
        const dataUpdate = [...background.education];
        const index = background.education.findIndex(edu => edu.id === education.id)
        dataUpdate[index] = education;
        setBackground({...background, 'education': dataUpdate});
        setIsEdit(!false);
      }
    } catch (e) {
      console.log(e);
    }
    setEducation(initialEducation);
    setLoading(false);
  }

  const handleEditExperience = (e) => {
    setExperience(e);
    setExperienceOpen(!experienceOpen);
    setIsEdit(true);
  }

  const handleAddOrCloseExperience = () => {
    setExperience(initialExperience);
    setExperienceOpen(!experienceOpen);
  }

  const handleExperienceChange = (e) => {
    setExperience({...experience, [e.target.name]: e.target.value});
  }

  const experienceValidation = () => {
    const valid = experience.title.length > 0 &&
                  experience.company.length > 0 &&
                  experience.employmentType.length > 0 &&
                  experience.startDate.length > 0 &&
                  experience.endDate.length > 0;
    return !valid;
  }

  function experienceDialog(){
    return (
      <Dialog
        open={experienceOpen}
        fullWidth={true}
        onClose={handleAddOrCloseExperience}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h5">
            Experience
          </Typography>
          <IconButton
            style={{right: '12px', top: '8px', position: 'absolute'}}
            onClick={handleAddOrCloseExperience}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <form>
            <div className="form-group row">
                <label htmlFor="company" className="col-md-3 col-form-label"><strong>Company</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           required
                           name="company"
                           placeholder="Company/Club/Research Instructor"
                           value={experience.company}
                           onChange={handleExperienceChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="title" className="col-md-3 col-form-label"><strong>Title</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="title"
                           placeholder="Position Title"
                           value={experience.title}
                           onChange={handleExperienceChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="employmentType" className="col-md-3 col-form-label"><strong>Employment Type</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="employmentType"
                           placeholder="Full Time/Part Time/Internship"
                           value={experience.employmentType}
                           onChange={handleExperienceChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="location" className="col-md-3 col-form-label"><strong>Location</strong></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="location"
                           placeholder="Work Location"
                           value={experience.location}
                           onChange={handleExperienceChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="startDate" className="col-md-3 col-form-label"><strong>Start Date</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="startDate"
                           type="date"
                           placeholder="e.g. 2015-08-15"
                           value={experience.startDate}
                           onChange={handleExperienceChange}
                    />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="endDate" className="col-md-3 col-form-label"><strong>End Date (expected)</strong><RequiredLabel /></label>
                <div className="col-md-9">
                    <InputBase className="form-control"
                           name="endDate"
                           type='date'
                           placeholder="e.g. 2019-05-02"
                           value={experience.endDate}
                           onChange={handleExperienceChange}
                    />
                </div>
            </div>

            <label htmlFor="description" className="col-form-label"><strong>Description</strong></label>
            <p>1. What were your tasks or responsibilities in this experience?</p>
            <p>2. What difficulties or challenges did you face?</p>
            <p>3. How did you solve the difficulties or challenges?</p>
            <p>4. What was the result or outcomes of you achieved in this experience?</p>
            <p>Please describe the experience as detailed as possible.</p>
            <textarea className="form-control"
                      name="description"
                      rows="8"
                      value={experience.description}
                      onChange={handleExperienceChange}
              >
              </textarea>
          </form>
        </DialogContent>
        <DialogActions>
          {
            isEdit ? <Button onClick={experienceDelete}
                                                         color="primary"
                                                  >
              Delete
            </Button>:null
          }
          <Button onClick={handleAddOrCloseExperience} color="primary">
            Cancel
          </Button>
          <Button onClick={experienceSubmit}
                  disabled={experienceValidation()}
                  color="primary"
                  autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const experienceSubmit = async() => {
    setLoading(true);
    setExperienceOpen(false);
    try {
      if (!isEdit){
        const apiRes = await API.graphql({ query: createExperience,
                            variables: { input: experience}
                          });
        setBackground({...background, 'experience': [...background.experience, apiRes.data.createExperience]});
      } else {
        const { updatedAt, createdAt, owner, ...experienceData } = experience;
        await API.graphql({ query: updateExperience,
                            variables: { input: experienceData }});
        const dataUpdate = [...background.experience];
        const index = background.experience.findIndex(exp => exp.id === experience.id)
        dataUpdate[index] = experience;
        setBackground({...background, 'experience': dataUpdate});
        setIsEdit(!false);
      }
    } catch (e) {
      console.log(e);
    }
    setExperience(initialExperience);
    setLoading(false);
  }

  const experienceDelete = async() => {
    try {
      const removeInput = {id: experience.id}
      await API.graphql({ query: deleteExperience,
                          variables: { input: removeInput }});
      const newBackground = {...background, 'experience': background.experience.filter(exp => exp.id !== experience.id)};
      setBackground(newBackground);
    } catch (e) {
      console.log(e);
    }
    setExperienceOpen(!experienceOpen);
    setExperience(initialExperience);
    setIsEdit(false);
  }



  return (
    <>
      <div className="row">
        <div className="col-md-3">
            <Typography variant="h5">
              Education
            </Typography>
        </div>
        <div className="col-md-9">
          {
            background.education? background.education.map((e) => (
              <Card style={{marginBottom: "10px"}} key={e.school}>
                <CardContent>
                  <Typography variant="h6">
                    {e.school}
                    <IconButton
                      style={{float: "right"}}
                      onClick={()=>handleEditEducation(e)}>
                      <EditIcon />
                    </IconButton>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {`${e.degree}, ${e.major}, ${e.gpa}/${e.gpaScale}`}
                  </Typography>
                  <Typography variant="body2" gutterBottom color="textSecondary" component="p">
                    {`${e.startDate} to ${e.endDate}`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{whiteSpace: 'pre-wrap'}}>
                    {e.description}
                  </Typography>
                </CardContent>
              </Card>
            )) : null
          }
          <Button
            style={{width: "100%", marginBottom: "10px"}}
            onClick={handleAddOrCloseEducation}
            variant="contained"
            startIcon={<AddIcon />}>
            Add Education
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3">
            <Typography variant="h5">
              Experience
            </Typography>
        </div>
        <div className="col-md-9">
          {
            background.experience? background.experience.map((e) => (
              <Card style={{marginBottom: "10px"}} key={e.title} >
                <CardContent>
                  <Typography variant="h6">
                    {`${e.title} `}
                    <IconButton
                      style={{float: "right"}}
                      onClick={()=>handleEditExperience(e)}>
                      <EditIcon />
                    </IconButton>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {`${e.employmentType}, ${e.company} @ ${e.location}`}
                  </Typography>
                  <Typography variant="body2" gutterBottom color="textSecondary" component="p">
                    {`${e.startDate} to ${e.endDate}`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{whiteSpace: 'pre-wrap'}}>
                    {e.description}
                  </Typography>
                </CardContent>
              </Card>
            )) : null
          }
          <Button
            style={{width: "100%", marginBottom: "10px"}}
            onClick={() => handleAddOrCloseExperience()}
            variant="contained"
            startIcon={<AddIcon />}>
            Add Experience
          </Button>
        </div>
      </div>
      {educationDialog()}
      {experienceDialog()}
      <Loading open={loading} />
    </>
  );
}

export default BackgroundTab;
