import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import { Route, Switch, Redirect } from 'react-router-dom';
import Calendar from '../calendar/Calendar';
import Applications from '../applications/Applications';
import FileTable from "../files/FileTable";
import GetSchools from "../utils/GetSchools";
import Account from "../account/Account";
import Recommendation from '../recommendation/Recommendation'
import { SchoolContext, CognitoUserContext, UserInfoContext } from "../utils/Contexts";
import Loading from '../utilsComponent/Loading';
import { getUser } from '../../graphql/queries';

function Sidebar() {
  const schools = GetSchools();
  const [ cognitoUser, setCognitoUser ] = useState({});
  const [ userInfo, setUserInfo ] = useState({});
  const [ isLoading, setLoading ] = useState(true);

  const fetchUserInfo = async() => {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const info = await API.graphql({ query: getUser, variables: { id: user.attributes.sub } });
      setCognitoUser(user);
      setUserInfo(info.data.getUser);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchUserInfo();
  }, [])

  const updateUserInfo = (key, val) => {
    setUserInfo({[key]: val});
  }

  return isLoading? <Loading open={isLoading} /> : (
      <BrowserRouter>
        <UserInfoContext.Provider value={{user: userInfo, updateUser: updateUserInfo}}>
          <CognitoUserContext.Provider value={cognitoUser}>
            <SidebarMenu />
            <div style={{ margin: "85px 20px 20px 250px"}}>
              <Switch>
                <SchoolContext.Provider value={schools}>
                  <Route
                      exact
                      path="/"
                      render={() => {
                          return (
                            <Redirect to="/Applications" />
                          );
                      }}
                    />
                  <Route exact path="/Files"><FileTable /></Route>
                  <Route exact path="/Applications"><Applications /></Route>
                  <Route exact path="/Calendar"><Calendar /></Route>
                  <Route exact path="/Account"><Account /></Route>
                  <Route exact path="/Recommender"><Recommendation /></Route>
                </SchoolContext.Provider>
              </Switch>
            </div>
          </CognitoUserContext.Provider>
        </UserInfoContext.Provider>
      </BrowserRouter>
  );
}

export default Sidebar;
