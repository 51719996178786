import { makeStyles } from '@material-ui/styles';

const styles = makeStyles((theme) => ({
  root: {
    justifyContent : "left",
  },
  drawer : {
    paddingTop : "60px",
    width: "230px",
    backgroundColor: "#262626",
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  btn: {
    color: "#ffffff",
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    '&:hover': {
      backgroundColor: "#808080",
      color: "#ffffff"
    }
  },
  btnSelected: {
    background: "white",
    color: "black"
  },
  btnRoot : {
    paddingLeft : "25px",
    justifyContent : "left !important"
  },
  subMenu : {
    paddingLeft : "50px !important",
  },
}));

export default styles;
