// TODO: after edit, add recommender will keep the edited recommender info;
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputBase from '@material-ui/core/InputBase';
import RequiredLabel from '../utilsComponent/RequiredLabel';
import { useStyles } from './RecommendationStyle';

const initialDialogRecommender = {
                            name: "",
                            email: "",
                            phone: "",
                            company: "",
                            position: "",
                            address1: "",
                            address2: "",
                            city: "",
                            state: "",
                            zip: "",
                            description: ""
                          }

function RecommenderToDialog(recommender) {
  const dialogRecommender = {...initialDialogRecommender};
  const dialogAddress = ['address1', 'address2', 'city', 'state', 'zip'];

  for (const prop in recommender) {
    if (!recommender[prop]) {
      continue
    }

    if (prop === 'address' && recommender[prop]) {
      const addressSplit = recommender[prop].split(', ');
      for (var i = 0; i < addressSplit.length; i++) {
        dialogRecommender[dialogAddress[i]] = addressSplit[i];
      }
    } else {
      dialogRecommender[prop] = recommender[prop]
    }
  }
  return dialogRecommender;
}

function DialogRecommenderToTableDate(dialogRecommender) {
  const recommender = {};
  const dialogAddress = ['address1', 'address2', 'city', 'state', 'zip'];
  var address = "";

  for (var i = 0; i < dialogAddress.length - 1; i++) {
    address += dialogRecommender[dialogAddress[i]] + ', ';
  }
  address += dialogRecommender[dialogAddress[i]];
  recommender['address'] = address;

  for (const prop in dialogRecommender) {
    if (!dialogAddress.includes(prop)) {
      recommender[prop] = dialogRecommender[prop];
    }
  }

  return recommender;
}

function RecommendationDialog(props) {
  // const tableRecommender = DialogRecommenderToTableDate(props.recommender);
  const [ recommender, setRecommender ] = useState(initialDialogRecommender);
  const styles = useStyles();

  useEffect(() => {
    const dialogRecommender = RecommenderToDialog(props.recommender);
    setRecommender(dialogRecommender);
  }, [props.recommender]);



  const recommenderValidation = () => {
    const valid = recommender.name.length > 0 &&
                  recommender.email.length > 0 &&
                  recommender.phone.length > 0 &&
                  recommender.company.length > 0 &&
                  recommender.position.length > 0 &&
                  recommender.address1.length > 0 &&
                  recommender.city.length > 0 &&
                  recommender.state.length > 0 &&
                  recommender.zip.length > 0;

    return !valid;
  }

  const handleChange = (e) => {
    setRecommender({...recommender, [e.target.name]: e.target.value});
  }

  const handleSubmit = () => {
    const tableRecommender = DialogRecommenderToTableDate(recommender);
    props.handleSubmit(tableRecommender);
    setRecommender(initialDialogRecommender);
  }

  return (
    <>
      <Dialog
        open={props.open}
        fullWidth={true}
        onClose={props.modifyOpenState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h5">
            Recommender
          </Typography>
          <IconButton
            style={{right: '12px', top: '8px', position: 'absolute'}}
            onClick={props.modifyOpenState}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={styles.dialogContent}>
        <form>
          <div className="form-group row mb-2">
              <label htmlFor="firstName" className="col-form-label col-md-3 "><strong>Name</strong><RequiredLabel /></label>
              <div className="col-md-9">
                  <InputBase className="form-control"
                         required
                         name="name"
                         placeholder="Name"
                         value={recommender.name}
                         onChange={handleChange}
                  />
              </div>
          </div>
          <div className="form-group row mb-2">
              <label htmlFor="email" className="col-form-label col-md-3 "><strong>Email</strong><RequiredLabel /></label>
              <div className="col-md-9 input-group">
                  <InputBase className="form-control"
                         required
                         name="email"
                         type="email"
                         placeholder="blablabla@blabla.com"
                         value={recommender.email}
                         onChange={handleChange}
                  />
              </div>
          </div>
          <div className="form-group row mb-2">
              <label htmlFor="phone" className="col-form-label col-md-3 "><strong>Phone</strong><RequiredLabel /></label>
              <div className="col-md-9">
                  <InputBase className="form-control"
                         required
                         name="phone"
                         placeholder="+1234569890"
                         value={recommender.phone}
                         onChange={handleChange}
                  />
              </div>
          </div>
          <div className="form-group row mb-2">
              <label htmlFor="phone" className="col-form-label col-md-3 "><strong>Company/School</strong><RequiredLabel /></label>
              <div className="col-md-9">
                  <InputBase className="form-control"
                         required
                         name="company"
                         placeholder="Company Name"
                         value={recommender.company}
                         onChange={handleChange}
                  />
              </div>
          </div>
          <div className="form-group row mb-2">
              <label htmlFor="phone" className="col-form-label col-md-3 "><strong>Position</strong><RequiredLabel /></label>
              <div className="col-md-9">
                  <InputBase className="form-control"
                         required
                         name="position"
                         placeholder="Professor/Colleage"
                         value={recommender.position}
                         onChange={handleChange}
                  />
              </div>
          </div>
          <div className="form-group row mb-2">
              <label htmlFor="address" className="col-form-label col-md-3"><strong>Address</strong><RequiredLabel /></label>
              <div className="col-md-9">
                  <InputBase className="form-control mb-2"
                         required
                         name="address1"
                         placeholder="Street Address"
                         value={recommender.address1}
                         onChange={handleChange}
                  />
                  <InputBase className="form-control mb-2"
                         name="address2"
                         placeholder="Apt"
                         value={recommender.address2}
                         onChange={handleChange}
                  />
                  <div className="row">
                    <div className="col-md-4">
                      <InputBase className="form-control"
                             required
                             name="city"
                             placeholder="City"
                             value={recommender.city}
                             onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-4">
                        <InputBase className="form-control"
                               required
                               name="state"
                               placeholder="State"
                               value={recommender.state}
                               onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-4">
                      <InputBase className="form-control"
                             required
                             name="zip"
                             placeholder="Zip Code"
                             value={recommender.zip}
                             onChange={handleChange}
                      />
                    </div>
                  </div>
              </div>
          </div>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.modifyOpenState} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit}
                  color="primary"
                  autoFocus
                  disabled={recommenderValidation()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RecommendationDialog;
