import React from 'react';
import Alert from '@material-ui/lab/Alert';

export const ErrorAlert = (msg) => {
  return (
    <Alert severity="error" style={{marginBottom: 15}}>
      {msg}
    </Alert>
  )
}

export const WarningAlert = (msg) => {
  return (
    <Alert severity="warning" style={{marginBottom: 15}}>
      {msg}
    </Alert>
  )
}

export const InfoAlert = (msg) => {
  return (
    <Alert severity="info" style={{marginBottom: 15}}>
      {msg}
    </Alert>
  )
}

export const SuccessAlert = (msg) => {
  return (
    <Alert severity="success" style={{marginBottom: 15}}>
      {msg}
    </Alert>
  )
}
