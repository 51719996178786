import React, {  useState, forwardRef } from 'react';
import clsx from 'clsx';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { List,
         ListItem,
         ListItemIcon,
         Icon,
         ListItemText,
         Collapse,
         Button,
         Drawer } from '@material-ui/core';
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { menuItems } from './SidebarMenuItems';
import useStyles from './SidebarMenuStyles';
import SidebarHeader from './SidebarHeader';
import { AmplifySignOut } from '@aws-amplify/ui-react';

function MenuBar(props) {
    const location = useLocation();
    const activeLocation = location.pathname.slice(1)? location.pathname.slice(1) : "Applications";
    const [ menu, setMenu ] = useState({activeButton: activeLocation});
    const { className, ...rest } = props;
    const classes  = useStyles();

    const displayItemChildren = (item) => {
        let newData = {...menu, [item] : !menu[item]};
        setMenu(newData);
    }

    const handleClick = (item) => {
        setMenu({
          activeButton: item
        })
    }

    const CustomRouterLink = forwardRef((props, ref) => (
        <RouterLink {...props} ref={ref} style={{ flexGrow: 1 }} />
    ));

    const handleMenu = ( children, level=0 ) => {
        return children.map((item) => {
          const buttonSelected = (item.name === menu.activeButton)? true: false;
          
          if ( !item.children ) {
            return (
              <List component="div" disablePadding key={item.name}>
                <ListItem
                  className={classes.item}
                  disableGutters
                  style={{padding:"0px"}}
                  key={item.name}
                  onClick={() => handleClick(item.name)}
                >
                  <Button
                    className={clsx({
                      [classes.btnRoot] : true,
                      [classes.btn] : true,
                      [classes.btnSelected]: buttonSelected,
                      [classes.subMenu] : level
                    })}
                    component={CustomRouterLink}
                    to={item.url}
                  >
                    <ListItemIcon>
                      <Icon style={{ color: buttonSelected? 'black':'white' }}>
                        {item.icon}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      primaryTypographyProps={{ noWrap: true }}
                    />
                  </Button>
                </ListItem>
              </List>
            )
          }
          return (
            <div key={ item.name }>
            <ListItem
              className={classes.item}
              disableGutters
              key={item.name}
              onClick={() => displayItemChildren(item.name)}
            >
              <Button
              className={clsx({
                [classes.btnRoot] : true,
                [classes.btn] : true,
                [classes.subMenu] : level
              })}>
                { item.name } { menu[ item.name ] ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </ListItem>
              <Collapse
                in={ (menu[item.name]) ? true : false }
                timeout="auto"
                unmountOnExit
              >
                { handleMenu( item.children, level+1) }
              </Collapse>
            </div>
          )
        })
    }

    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            open={true}
            variant="persistent"
          >
            <SidebarHeader />
            <List {...rest} className={clsx(classes.root, className)} >
                { handleMenu(menuItems.data) }
            </List>
            <AmplifySignOut />
        </Drawer>
   )
}

export default MenuBar;
