import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import InputBase from '@material-ui/core/InputBase';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './AccountStyles';
import { genderList } from '../lists/GenderList';
import { CognitoUserContext } from "../utils/Contexts";
import { Auth } from 'aws-amplify';
import { ErrorAlert, WarningAlert } from '../alerts/Alerts';

const msgs = {
  EmailNotVerified: "Please verify email before proceed.",
}

function GeneralTab(props) {
  const styles = useStyles();
  const userProfile = props.userInfo;
  const cognitoUser = useContext(CognitoUserContext);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ cognitoUserData, setCognitoUserData ] = useState(cognitoUser.attributes);
  const [ emailVerificationOpen, setEmailVerificationOpen ] = useState(false);
  const [ emailVerified, setEmailVerified ] = useState(cognitoUser.attributes.email_verified);
  const [ emailVerificationCode, setEmailVerificationCode ] = useState("");
  const [ emailVerificationErrorMsg, setEmailVerificationErrorMsg ] = useState("");

  const handleFormCancel = (e) => {
    e.preventDefault();
    setIsEdit(!isEdit);
    setCognitoUserData(cognitoUser.attributes);
    setEmailVerified(cognitoUser.attributes.email_verified);
    props.handleFormCancel();
  }

  const handleFormSubmit = () => {
    props.handleFormSubmit();
    setIsEdit(!isEdit);
  }

  const handleFormChange = (e) => {
    if (e.target.name === 'email') {
      const emailSame = e.target.value === cognitoUser.attributes.email;
      setEmailVerified(emailSame);
      setCognitoUserData({...cognitoUserData, [e.target.name]: e.target.value})
    } else {
      props.handleFormChange(e);
    }
  }

  const handleEmailValidation = () => {
    const correctFormat = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    return !correctFormat.test(cognitoUserData.email)
  }

  const handleEmailVerificationSend = async() => {
    setEmailVerificationErrorMsg("");
    try {
      if (cognitoUser.attributes.email === cognitoUserData.email && cognitoUser.attributes.email_verified === false) {
        await Auth.verifyCurrentUserAttribute("email");
      } else {
        await Auth.updateUserAttributes(cognitoUser, { email: cognitoUserData.email });
      }
      setEmailVerificationOpen(!emailVerificationOpen);
    } catch (error) {
      console.log(error);
      setEmailVerificationErrorMsg(error.message);
    }
  }

  const handleEmailVerificationResend = async(e) => {
    e.preventDefault();
    setEmailVerificationErrorMsg("");
    try {
      await Auth.verifyCurrentUserAttribute("email"); // Nothing will return
    } catch (error) {
      console.log(error)
    }
  }

  const handleEmailVerificationSubmit = async(e) => {
    e.preventDefault();
    setEmailVerificationErrorMsg("");
    try {
      const apiRes = await Auth.verifyCurrentUserAttributeSubmit("email", emailVerificationCode);
      setEmailVerificationOpen(!emailVerificationOpen);
      if (apiRes === 'SUCCESS') {
        setEmailVerified(true);
      }
    } catch (error) {
      setEmailVerificationErrorMsg(error.message);
    }
  }

  const handleEmailVerificationCancel = async() => {
    setEmailVerificationOpen(!emailVerificationOpen);
    setEmailVerificationCode("");
    setEmailVerificationErrorMsg("");
  }

  const emailVerificationForm = () => {
    if (!emailVerified) {
      return (
        <OverlayTrigger
          trigger="click"
          placement="top"
          show={emailVerificationOpen}
          overlay={
            <Popover id="popover-basic">
              <Popover.Content>
                <div className="form-group">
                  <label htmlFor="code" className="col-form-label">Please enter the code: </label>
                  <div className="input-group">
                    <InputBase className="form-control"
                               name="code"
                               type="text"
                               required
                               onChange={(e) => setEmailVerificationCode(e.target.value)}
                    />
                    <div className="input-group-append">
                      <Button className="btn btn-outline-secondary"
                              variant="outlined"
                              color="primary"
                              onClick={handleEmailVerificationResend}
                      >
                              Resend
                      </Button>
                    </div>
                  </div>
                  <p style={{color: "red"}}> {emailVerificationErrorMsg} </p>
                </div>
                <Button className='float-right mb-2'
                        variant="primary"
                        type="submit"
                        disabled={!emailVerificationCode.length > 0}
                        onClick={handleEmailVerificationSubmit}>
                  Submit
                </Button>
                <Button className='float-right mb-2 mr-2'
                        variant="secondary"
                        onClick={handleEmailVerificationCancel}
                >
                  Cancel
                </Button>
              </Popover.Content>
            </Popover>
          }
        >
          <Tooltip title="Please verify email before proceed!">
            <Button className="btn btn-outline-dark"
                    variant="outlined"
                    color="primary"
                    onClick={handleEmailVerificationSend}
                    disabled={handleEmailValidation()}
            >
                    Verify
            </Button>
          </Tooltip>
        </OverlayTrigger>
      )
    }
    else {
      return (
        <div className="valid-feedback feedback-icon">
            <i className="fa fa-check"></i>
        </div>
      )
    }
  }

  const displayMsg = () => {
    if (!emailVerified) {
      return WarningAlert(msgs.EmailNotVerified);
    }
  }

  return (
    <div className="container">
      <form>
        {displayMsg()}
        {props.msg.length > 0? ErrorAlert(props.msg) : ""}
        <div className="form-group row mb-2">
            <label htmlFor="firstName" className="col-form-label col-md-2 offset-md-2">Name</label>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <InputBase className="form-control"
                         required
                         disabled={!isEdit}
                         name="firstName"
                         placeholder="First Name"
                         value={userProfile.firstName}
                         onChange={handleFormChange}
                  />
                </div>
                <div className="col-md-6">
                  <InputBase className="form-control"
                         required
                         disabled={!isEdit}
                         name="lastName"
                         placeholder="Last Name"
                         value={userProfile.lastName}
                         onChange={handleFormChange}
                  />
                </div>
              </div>
            </div>
        </div>
        <div className="form-group row mb-2">
            <label htmlFor="gender" className="col-form-label col-md-2 offset-md-2">Gender</label>
            <div className="col-md-6">
              {
                isEdit? (
                  <select className="form-control"
                         required
                         disabled={!isEdit}
                         name="gender"
                         onChange={handleFormChange}
                         value={userProfile.gender}
                  >
                    {genderList.map((gender) => (
                      <option value={gender} key={gender}> {gender} </option>
                    ))}
                  </select>
                ) : (
                  <InputBase className="form-control"
                             disabled={!isEdit}
                             name="gender"
                             placeholder="male/female/others"
                             value={userProfile.gender}
                  />
                )
              }
            </div>
        </div>
        <div className="form-group row mb-2">
            <label htmlFor="birthDate" className="col-form-label col-md-2 offset-md-2">Birth Date</label>
            <div className="col-md-6">
                <InputBase className="form-control"
                           required
                           type='date'
                           disabled={!isEdit}
                           name="birthDate"
                           value={userProfile.birthDate}
                           onChange={handleFormChange}
                />
            </div>
        </div>
        <div className="form-group row mb-2">
            <label htmlFor="email" className="col-form-label col-md-2 offset-md-2">Email</label>
            <div className="col-md-6 input-group">
                <InputBase className={`form-control ${isEdit && emailVerified? "is-valid" : ""}`}
                       required
                       disabled={!isEdit}
                       name="email"
                       type="email"
                       placeholder="blablabla@blabla.com"
                       value={cognitoUserData.email}
                       onChange={handleFormChange}
                />
                <div className="input-group-append">
                  {isEdit? emailVerificationForm():""}
                </div>
            </div>
        </div>
        <div className="form-group row mb-2">
            <label htmlFor="phone" className="col-form-label col-md-2 offset-md-2">Phone</label>
            <div className="col-md-6">
                <InputBase className="form-control"
                       required
                       disabled={!isEdit}
                       name="phone"
                       placeholder="+1234567890"
                       value={userProfile.phone}
                       onChange={handleFormChange}
                />
            </div>
        </div>
        <div className="form-group row mb-2">
            <label htmlFor="address" className="col-form-label col-md-2 offset-md-2">Address</label>
            <div className="col-md-6">
                <InputBase className="form-control mb-2"
                       required
                       disabled={!isEdit}
                       name="address1"
                       placeholder="Street Address"
                       value={userProfile.address1}
                       onChange={handleFormChange}
                />
                <InputBase className="form-control mb-2"
                       disabled={!isEdit}
                       name="address2"
                       placeholder="Apt"
                       value={userProfile.address2}
                       onChange={handleFormChange}
                />
                <div className="row">
                  <div className="col-md-4">
                    <InputBase className="form-control"
                           required
                           disabled={!isEdit}
                           name="city"
                           placeholder="City"
                           value={userProfile.city}
                           onChange={handleFormChange}
                    />
                  </div>

                  <div className="col-md-4">
                      <InputBase className="form-control"
                             required
                             disabled={!isEdit}
                             name="state"
                             placeholder="State"
                             value={userProfile.state}
                             onChange={handleFormChange}
                      />
                  </div>

                  <div className="col-md-4">
                    <InputBase className="form-control"
                           required
                           disabled={!isEdit}
                           name="zip"
                           placeholder="Zip Code"
                           value={userProfile.zip}
                           onChange={handleFormChange}
                    />
                  </div>
                </div>
            </div>
        </div>
        <div className="form-group row mb-2">
            <label htmlFor="description" className="col-form-label col-md-2 offset-md-2">About Me</label>
            <div className="col-md-6">
                <textarea className={`form-control ${styles.input}`}
                          name="description"
                          rows="3"
                          value={userProfile.description}
                          onChange={handleFormChange}
                          disabled={!isEdit}
                  >
                </textarea>
            </div>
        </div>
        <div className="mt-3">
          {
            isEdit? (
              <React.Fragment>
                <Button className={`${styles.button} float-right mb-2 mr-2`}
                        variant="primary"
                        type="submit"
                        disabled={!emailVerified}
                        onClick={handleFormSubmit}
                >
                  Submit
                </Button>
                <Button className={`${styles.button} float-right mb-2 mr-2`}
                        type="reset"
                        variant="secondary"
                        onClick={handleFormCancel}
                >
                  Cancel
                </Button>
              </React.Fragment>)
             : (
            <Button className={`${styles.button} float-right mb-2`}
                    variant="primary"
                    type="button"
                    onClick={(e)=>{e.preventDefault();setIsEdit(!isEdit)}}
            >
              Edit
            </Button>)
          }
        </div>
      </form>
    </div>
  );
}

export default GeneralTab;
