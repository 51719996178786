import React, { useContext } from 'react';
import { Avatar, Typography, Divider } from '@material-ui/core/';
import useStyles from './SidebarHeaderStyles';
import { CognitoUserContext } from "../utils/Contexts";

function SidebarHeader(props) {
  const styles = useStyles();
  const user = useContext(CognitoUserContext);
  
  return (
    <>
      <div className={styles.root}>
        <Avatar
          style={{
            width: 60,
            height: 60,
          }}
        />
        <div style={{ paddingBottom: 16 }} />
        <Typography variant={"h6"} noWrap>
          { user.username }
        </Typography>
        <Typography noWrap gutterBottom>
          { user.attributes.email }
        </Typography>
      </div>
      <Divider className={styles.divider} />
    </>
  );
}

export default SidebarHeader;
