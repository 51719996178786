import React, { useState, useContext } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import GeneralTab from './GeneralTab';
import BackgroundTab from './BackgroundTab';
import AdditionalInfoTab from './AdditionalInfoTab';
import ChangePasswordTab from './ChangePasswordTab';
import ReferralTab from './ReferralTab';
import { useStyles } from './AccountStyles';
import { initialUserInfo } from './InitialForms';
import { UserInfoContext } from "../utils/Contexts";
import { API } from 'aws-amplify';
import { updateUser } from '../../graphql/mutations';

function DbUserToData(dbUser) {
  // in case the attributes not in the db yet
  const userData = initialUserInfo;
  for (var key in dbUser){
    if (dbUser[key]){
      userData[key] = dbUser[key];
    }
  }

  return userData;
}

function Account() {
  const [tab, setTab] = useState('general');
  const styles = useStyles();
  const dbUserInfo = useContext(UserInfoContext);
  const userProfile = DbUserToData(dbUserInfo.user);
  const [ userInfoChange, setUserInfoChange ] = useState({...userProfile});
  const [ errorMsg, setErrorMsg ] = useState("");

  const handleFormChange = (e) => {
    e.preventDefault()
    setUserInfoChange({...userInfoChange, [e.target.name]: e.target.value});
  }

  const handleFormCancel = () => {
    setUserInfoChange(userProfile);
  }

  const handleFormSubmit = async () => {
    try {
      const { updatedAt, createdAt, ...newUserInfo } = userInfoChange;

      const apiUser = {}
      for (var prop in newUserInfo) {
        if (!newUserInfo[prop]) {
          continue
        } else {
          apiUser[prop] = newUserInfo[prop];
        }
      }

      const apiRes = await API.graphql({ query: updateUser,
                          variables: { input: apiUser }});
      const newDbUser = apiRes.data.updateUser
      const newUserData = DbUserToData(newDbUser);

      dbUserInfo.updateUser(newUserData);
    } catch (e) {
      setErrorMsg(e.message);
    }
  }

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={tab}
      onSelect={(t) => setTab(t)}
      className={styles.root}
    >
      <Tab eventKey="general" title="General">
        <GeneralTab userInfo = {userInfoChange}
                    msg={errorMsg}
                    handleFormChange = {handleFormChange}
                    handleFormCancel = {handleFormCancel}
                    handleFormSubmit = {handleFormSubmit}
        />
      </Tab>
      <Tab eventKey="background" title="Background">
        <BackgroundTab />
      </Tab>
      <Tab eventKey="additionInfo" title="Additional Info">
        <AdditionalInfoTab userInfo = {userInfoChange}
                    handleFormChange = {handleFormChange}
                    handleFormCancel = {handleFormCancel}
                    handleFormSubmit = {handleFormSubmit}
        />
      </Tab>
      <Tab eventKey="changePassword" title="Change Password">
        <ChangePasswordTab />
      </Tab>
      <Tab eventKey="referral" title="Referral">
        <ReferralTab dbUser = {dbUserInfo.user}
                  userInfo={userInfoChange}
                  handleFormChange = {handleFormChange}
                  handleFormCancel = {handleFormCancel}
                  handleFormSubmit = {handleFormSubmit}
        />
      </Tab>
    </Tabs>
  );
}

export default Account;
