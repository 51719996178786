/*
// TODO:
  1. test timezone
  2. set up errors handler
*/

import React, { useState, useEffect, useContext } from 'react';
import Scheduler, { Resource, View } from 'devextreme-react/scheduler';
import CustomStore from "devextreme/data/custom_store";
import Grid from '@material-ui/core/Grid';
// import RightSidebarCalendars from './rightSidebarCalendars';
import Alert from '@material-ui/lab/Alert';
import { API } from 'aws-amplify';
import { listCalendarEvents } from '../../graphql/queries';
import { createCalendarEvent, deleteCalendarEvent, updateCalendarEvent } from '../../graphql/mutations';
import { SchoolContext } from "../utils/Contexts";
import CircularProgress from '@material-ui/core/CircularProgress';

const dayOfWeekNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function Calendar() {
  const [errorMessages, setErrorMessages] = useState([]);
  const [events, setEvents] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const schools = useContext(SchoolContext);

  useEffect(() => {
    fetchCalendarEvents();
  }, [])

  const fetchCalendarEvents = async () => {
    try {
      const calendarData = await API.graphql({ query: listCalendarEvents });
      setEvents(calendarData.data.listCalendarEvents.items);
      setDataFetched(true);
    } catch (e) {
      setErrorMessages(["Server error! Please refresh the page and try again."]);
    }
  }

  const typeCalendars = schools.map(school => ({
    text: school.name,
    id: school.id,
    color: school.calendarColor
  }));

  const renderWeekCell = (cellData) => {
    return (
      <React.Fragment>
        <div className="name">{dayOfWeekNames[cellData.date.getDay()]}</div>
        <div className="number">{cellData.date.getDate()}</div>
      </React.Fragment>
    );
  }

  const schedulerDataSource = {
      store: new CustomStore({
          load: () => {
              return events;
          },
          insert: async (value) => {
              const addInput = {
                                  text: value.text? value.text: null,
                                  startDate: value.startDate,
                                  endDate: value.endDate,
                                  typeId: value.typeId? value.typeId: null,
                                  description: value.description? value.description: null,
                                  recurrenceRule: value.recurrenceRule? value.recurrenceRule: null,
                                  recurrenceException: value.recurrenceException? value.recurrenceException: null
                              }
              const apiRes = await API.graphql({ query: createCalendarEvent,
                                  variables: { input: addInput }
                                });
              setEvents([...events, apiRes.data.createCalendarEvent]);
          },
          remove: async (key) => {
              const removeInput = {id: key.id}
              await API.graphql({ query: deleteCalendarEvent,
                                  variables: { input: removeInput }});
              const newCalendarEventsArray = events.filter(event => event.id !== key.id);
              setEvents(newCalendarEventsArray);
          },
          update: async (oldValues, newValues) => {
              const { updatedAt, createdAt, owner, ...newDataInfo } = newValues;
              await API.graphql({ query: updateCalendarEvent,
                                  variables: { input: newDataInfo }});
              const dataUpdate = [...events];
              const index = events.findIndex(event => event.id === newValues.id)
              dataUpdate[index] = newDataInfo;
              setEvents([...dataUpdate]);
          },
          paginate: false
      })
  }

  return !dataFetched?
          (<div style={{display: 'flex',
                        marginTop: '200px',
                        justifyContent: 'center'}}>
            <CircularProgress />
          </div>): (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div>
          {errorMessages.length > 0 &&
            <Alert severity="error">
                {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>
                })}
            </Alert>
          }
        </div>
        <Scheduler
          dataSource={schedulerDataSource}
          defaultCurrentView="month"
          showAllDayPanel={false}
          showCurrentTimeIndicator={true}
          height={550}
          startDayHour={0}
          endDayHour={24}
        >
          <View
            type="day"
          />
          <View
            type="week"
            dateCellRender={renderWeekCell}
          />
          <View
            type="month"
          />
          <View
            type="agenda"
          />
          <Resource
            dataSource={typeCalendars}
            fieldExpr="typeId"
            label="Type"
            allowMultiple={false}
          />
        </Scheduler>
      </Grid>{/*
      <Grid item xs={3}>
        <RightSidebarCalendars schools={schools} />
      </Grid>*/}
    </Grid>
  );
}

export default Calendar;
