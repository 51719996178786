import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5px 65px 5px 65px",
    textAlign: 'center'
  },
  button: {
    margin: theme.spacing(0.5),
    width: "200px",
    fontWeight: "bold",
    textAlign: "center"
  },
  editButton: {
    background: '#808080',
    color: "white",
    '&:hover': {
      backgroundColor: "#b3b3b3",
    }
  }
}));
