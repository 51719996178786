export const initialUserInfo = {
                              id: "",
                              firstName: "",
                              lastName: "",
                              gender: "",
                              birthDate: "",
                              phone: "",
                              address1: "",
                              address2: "",
                              city: "",
                              state: "",
                              zip: "",
                              goal: "",
                              reasonToStudyGraduateSchool: "",
                              majorApplying: "",
                              plan: "",
                              referrer: ""
                            };

export const initialEducation = {
                                school: "",
                                degree: "",
                                major: "",
                                gpa: "",
                                gpaScale: "",
                                startDate: "",
                                endDate: "",
                                description: ""
                              };

export const initialExperience = {
                                title: "",
                                company: "",
                                employmentType: "",
                                location: "",
                                startDate: "",
                                endDate: "",
                                description: ""
                              };

export const initialPasswordForm = {
                                  oldPassword: '',
                                  newPassword: '',
                                  confirmPassword: '',
                                };
