export const fileCategories = [
  {
    value: 'Personal Statement',
    label: 'Personal Statement',
  },
  {
    value: 'Statement of Purpose',
    label: 'Statement of Purpose',
  },
  {
    value: 'Recommendation',
    label: 'Recommendation',
  },
  {
    value: 'Resume/CV',
    label: 'Resume/CV',
  },
  {
    value: 'Others',
    label: 'Others'
  }
];
