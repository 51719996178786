export const menuItems = {
    "data" : [
      {
          "name": "Files",
          "url": "/Files",
          "icon": "folder"
      },
      {
        "name": "Account",
        "url": "/Account",
        "icon": "account_circle"
      },
      {
        "name": "Applications",
        "url": "/Applications",
        "icon": "school"
      },
      {
        "name": "Recommender",
        "url": "/Recommender",
        "icon": "group"
      },
      // {
      //     "name": "Dashboard",
      //     "url": "/Dashboard",
      //     "icon": "dashboard"
      // },
      {
        "name": "Calendar",
        "url": "/Calendar",
        "icon": "event"
      },
      // {
      //   "name": "Bug Report",
      //   "url": "/BugReport",
      //   "icon": "bug_report"
      // },
      // {
      //   "name": "Messages",
      //   "url": "/Messages",
      //   "icon": "email"
      // },
      // {
      //   "name": "Settings",
      //   "url": "/Settings",
      //   "icon": "settings"
      // },
      // {
      //   "name": "Item3",
      //   "children": [
      //     {
      //       "name": "Child31",
      //       "url": "/Child31"
      //     },
      //     {
      //       "name": "Child32",
      //       "url": "/Child32"
      //     },
      //     {
      //       "name": "Child33",
      //       "url": "/Child33"
      //     }
      //   ]
      // },
      // {
      //   "name": "Item4",
      //   "children": [
      //     {
      //       "name": "Child41",
      //       "url": "/Child41"
      //     },
      //     {
      //       "name": "Child42",
      //       "url": "/child42"
      //     }
      //   ]
      // }
    ]
  }
