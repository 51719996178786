import React from 'react';

function RequiredLabel(props) {
  return (
    <>
    <span style={{color: "red"}}>*</span>
    </>
  )
}

export default RequiredLabel;
