import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from "material-table";
import DetailPanel from '../TableDetailPanel/DetailPanel';
import { listFiles } from '../../graphql/queries';
import { updateFile, deleteFile } from '../../graphql/mutations';
import FileUpload from './FileUpload';
import { SchoolContext } from "../utils/Contexts";
import { API, Storage } from 'aws-amplify';

import { getTableColumns } from './FileTableColumns';

function FileTable() {
  const [ uploadFileOpen, setUploadFileOpen ] = useState(false);
  const [ files, setFiles ] = useState([]);
  const [ dataFetched, setDataFetched ] = useState(false);
  const schools = useContext(SchoolContext);
  const tableColumns = getTableColumns(schools);

  const dbFileToTableFile = (file) => {
    return {...file,
            createdAt: new Date(file["createdAt"]).toString()
                                                  .split(" ")
                                                  .slice(1,4)
                                                  .join(" "),
            }
  }

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const apiData = await API.graphql({ query: listFiles });
        const apiFiles = apiData.data.listFiles.items;

        const tableFiles = apiFiles.map((file) => dbFileToTableFile(file));
        setFiles(tableFiles);
        setDataFetched(true);
      } catch (e) {
        console.log(e);
      }
    }
    fetchFiles();
  }, [])

  function modifyOpenState(val) {
    setUploadFileOpen(val);
  }

  function addFile(file) {
    const tableFile = dbFileToTableFile(file);
    setFiles([...files, tableFile]);
  }

  const handleRowUpdate = async (newData, oldData, resolve, reject) => {
    try {
      const { updatedAt, createdAt, owner, tableData, ...rowData } = newData;

      await API.graphql({ query: updateFile,
                          variables: { input: rowData }});
      const dataUpdate = [...files];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setFiles([...dataUpdate]);
      resolve();
    } catch (e) {
      reject(e);
    }
  }

  const handleRowDelete = async (id, resolve) => {
    try {
      await API.graphql({ query: deleteFile,
                          variables: { input: { id } }});

      const deletedFile = files.filter(file => file.id === id);
      Storage.remove(deletedFile[0].name)
             .catch(err => console.log(err));

      const newFilesArray = files.filter(file => file.id !== id);
      setFiles(newFilesArray);
      resolve()
    } catch (e) {
      resolve()
    }
  }

  const handleFileDownload = async (rowData, resolve, reject) => {
    await Storage.get(rowData.name, { download: true })
                              .then(res => {
                                let link = document.createElement("a");
                                link.download = rowData.name;
                                link.href = URL.createObjectURL(res.Body);
                                link.click();
                                URL.revokeObjectURL(link.href);
                              });
  }

  return (
    <>
      <MaterialTable
        title="Files"
        columns={tableColumns}
        data={files}
        options={{
          grouping: true,
          headerStyle: {
            fontWeight: "bold",
            fontSize: 16,
            position: 'sticky',
            top: 0
          },
          actionsColumnIndex: -1,
          paging: false,
          minBodyHeight: "450px",
          maxBodyHeight: "480px"
        }}
        isLoading={!dataFetched}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
                handleRowUpdate(newData, oldData, resolve, reject);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData.id, resolve)
            }),
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Upload Files',
            isFreeAction: true,
            onClick: (event) => modifyOpenState(true)
          },
          {
          icon: 'cloud_download',
          tooltip: 'Download File',
          onClick: (event, rowData) =>
            new Promise((resolve, reject) => {
                handleFileDownload(rowData, resolve, reject);
            })
          },
        ]}
        detailPanel={ rowData => <DetailPanel rowData={rowData} handleRowUpdate={handleRowUpdate} />}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
      <FileUpload open={uploadFileOpen} setOpenState={modifyOpenState} addFilesToTable={addFile}/>
    </>
  )
}

export default FileTable;
