/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      name
      program
      due
      account
      status
      description
      calendarColor
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        program
        due
        account
        status
        description
        calendarColor
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCalendarEvent = /* GraphQL */ `
  query GetCalendarEvent($id: ID!) {
    getCalendarEvent(id: $id) {
      id
      text
      startDate
      endDate
      typeId
      description
      recurrenceRule
      recurrenceException
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCalendarEvents = /* GraphQL */ `
  query ListCalendarEvents(
    $filter: ModelCalendarEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendarEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        startDate
        endDate
        typeId
        description
        recurrenceRule
        recurrenceException
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      name
      size
      item
      school
      program
      category
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        size
        item
        school
        program
        category
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      gender
      birthDate
      phone
      address1
      address2
      city
      state
      zip
      description
      goal
      reasonToStudyGraduateSchool
      majorApplying
      plan
      referrer
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        gender
        birthDate
        phone
        address1
        address2
        city
        state
        zip
        description
        goal
        reasonToStudyGraduateSchool
        majorApplying
        plan
        referrer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEducation = /* GraphQL */ `
  query GetEducation($id: ID!) {
    getEducation(id: $id) {
      id
      school
      degree
      major
      gpa
      gpaScale
      startDate
      endDate
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEducations = /* GraphQL */ `
  query ListEducations(
    $filter: ModelEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school
        degree
        major
        gpa
        gpaScale
        startDate
        endDate
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExperience = /* GraphQL */ `
  query GetExperience($id: ID!) {
    getExperience(id: $id) {
      id
      title
      company
      employmentType
      location
      startDate
      endDate
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listExperiences = /* GraphQL */ `
  query ListExperiences(
    $filter: ModelExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExperiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        company
        employmentType
        location
        startDate
        endDate
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getReferee = /* GraphQL */ `
  query GetReferee($id: ID!) {
    getReferee(id: $id) {
      id
      username
      refererId
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listReferees = /* GraphQL */ `
  query ListReferees(
    $filter: ModelRefereeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        refererId
        status
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRecommender = /* GraphQL */ `
  query GetRecommender($id: ID!) {
    getRecommender(id: $id) {
      id
      name
      email
      phone
      company
      position
      address
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRecommenders = /* GraphQL */ `
  query ListRecommenders(
    $filter: ModelRecommenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecommenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        company
        position
        address
        description
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
