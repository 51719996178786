import React, { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputBase from '@material-ui/core/InputBase';
import Button from 'react-bootstrap/Button';
import { useStyles } from './AccountStyles';
import { ErrorAlert, WarningAlert, SuccessAlert } from '../alerts/Alerts';
import Loading from '../utilsComponent/Loading';
import { CognitoUserContext } from "../utils/Contexts";

const msgs = {
  passwordNotMatch: "Password and confirm password do not match!",
  passwordNotChange: "The old password is the same as the new password. Please type a new password!",
  success: "Success! The password has been changed!",
  error: "The old password is not correct. Please try again!"
}

const initialPasswordForm = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

export default function ChangePasswordTab() {
  const styles = useStyles();
  const user = useContext(CognitoUserContext);
  const [ passwords, setPasswords ] = useState(initialPasswordForm);
  const [ showPassword, setShowPassword ] = useState(false);
  const [ passwordChangeRes, setPasswordChangeRes ] = useState("");
  const [ loading, setLoading ] = useState(false);

  const displayWarning = () => {
    if (passwords.oldPassword && passwords.newPassword
                              && passwords.oldPassword === passwords.newPassword) {
      return WarningAlert(msgs.passwordNotChange);
    }
    if (passwords.newPassword && passwords.confirmPassword
                              && passwords.newPassword !== passwords.confirmPassword) {
      return WarningAlert(msgs.passwordNotMatch);
    }
  }

  const passwordValidation = () => {
    return passwords.oldPassword.length > 0 &&
           passwords.newPassword.length > 0 &&
           passwords.confirmPassword.length > 0 &&
           passwords.oldPassword !== passwords.newPassword &&
           passwords.newPassword === passwords.confirmPassword;
  }

  const handleChange = (e) => {
    setPasswordChangeRes("");
    setPasswords({...passwords, [e.target.name]: e.target.value});
  }

  const handleChangeClick = async () => {
    setLoading(!loading);
    try {
      const apiRes = await Auth.changePassword(
        user,
        passwords.oldPassword,
        passwords.newPassword
      );
      setPasswordChangeRes(apiRes);
      setPasswords(initialPasswordForm);
    } catch (error) {
      setPasswordChangeRes(error.message);
    }
    setLoading(false);
  }

  const passwordChangeResult = () => {
    if ( passwordChangeRes === "SUCCESS" ) {
      return SuccessAlert(passwordChangeRes);
    }
    else if (passwordChangeRes !== ""){
      return ErrorAlert(passwordChangeRes);
    }
  }

  return (
    <React.Fragment>
    <form className={styles.centerForm}>
      {displayWarning()}
      {passwordChangeResult()}
      <div className="form-group row">
          <label htmlFor="oldPassword" className="col-md-3 col-form-label"><strong>Old Password</strong></label>
          <div className="col-md-9">
              <InputBase className="form-control"
                     required={true}
                     name="oldPassword"
                     placeholder="Old Password"
                     onChange={handleChange}
                     value={passwords.oldPassword}
              />
          </div>
      </div>
      <hr />
      <div className="form-group row">
          <label htmlFor="newPassword" className="col-md-3 col-form-label"><strong>New Password</strong></label>
          <div className="col-md-9" style={{position: 'relative', display: 'inline-block'}}>
            <InputBase
              className="form-control"
              required={true}
              name="newPassword"
              placeholder="New Password"
              type={showPassword ? 'text' : 'password'}
              value={passwords.newPassword}
              onChange={handleChange}
            />
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              style={{position: 'absolute', right: 13, top: -5}}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
      </div>
      <div className="form-group row">
          <label htmlFor="confirmPassword" className="col-md-3 col-form-label"><strong>Confirm Password</strong></label>
          <div className="col-md-9">
              <InputBase className="form-control"
                     required={true}
                     name="confirmPassword"
                     placeholder="Retype Password"
                     type="password"
                     onChange={handleChange}
                     value={passwords.confirmPassword}
              />
          </div>
      </div>
      <Button className={`${styles.button} float-right mb-2 mr-2`}
              variant="primary"
              onClick={handleChangeClick}
              disabled={!passwordValidation()}
      >
        Submit
      </Button>
      </form>
      <Loading open={loading} />
    </React.Fragment>
  );
}
