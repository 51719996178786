/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:a0276e4d-b3ed-4875-ad88-3457422fc474",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_8BBvwlG5W",
    "aws_user_pools_web_client_id": "52bqh2d8bfhe3jgh2aa04vln7a",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://fcljawj5ancmnnlduskzaho3h4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ae5evsdhrnh2dgwgroysyskdmm",
    "aws_user_files_s3_bucket": "s3studyweb73415-staging",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "userPoolId": "us-west-2_8BBvwlG5W",
    "userPoolWebClientId": "52bqh2d8bfhe3jgh2aa04vln7a"
};


export default awsmobile;
