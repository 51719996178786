import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useStyles } from './AccountStyles';

function AdditionalInfoTab(props) {
  const styles = useStyles();
  const user = props.userInfo;
  const [ isEdit, setIsEdit ] = useState(false);

  const handleFormCancel = () => {
    props.handleFormCancel();
    setIsEdit(!isEdit);
  }

  const handleFormSubmit = () => {
    props.handleFormSubmit()
    setIsEdit(!isEdit);
  }

  return (
    <form style={{marginBottom: 70}}>
      <div className="form-group">
        <label htmlFor="goal" className="col-form-label">
        1. Do you have any application goals? Like what's your dream school?
        </label>
        <textarea className="form-control"
                  name="goal"
                  rows="3"
                  value={user.goal}
                  onChange={props.handleFormChange}
                  disabled={!isEdit}
          >
        </textarea>
      </div>

      <div className="form-group">
        <label htmlFor="reasonToStudyGraduateSchool" className="col-form-label">
        2. Why do you want to study at graduate school?
        </label>
        <textarea className="form-control"
                  name="reasonToStudyGraduateSchool"
                  rows="3"
                  value={user.reasonToStudyGraduateSchool}
                  onChange={props.handleFormChange}
                  disabled={!isEdit}
          >
        </textarea>
      </div>

      <div className="form-group">
        <label htmlFor="majorApplying" className="col-form-label">
        3. What major are you applying to? Why do you want to study in this field? What's your study plan?
        </label>
        <textarea className="form-control"
                  name="majorApplying"
                  rows="3"
                  value={user.majorApplying}
                  onChange={props.handleFormChange}
                  disabled={!isEdit}
          >
        </textarea>
      </div>

      <div className="form-group">
        <label htmlFor="plan" className="col-form-label">
        4. What's your plans for the next 3-5 years? What's your education goal? What's your career goal?
        </label>
        <textarea className="form-control"
                  name="plan"
                  rows="3"
                  value={user.plan}
                  onChange={props.handleFormChange}
                  disabled={!isEdit}
          >
        </textarea>
      </div>

      <div className="mt-3">
        {
          isEdit? (
            <React.Fragment>
              <Button className={`${styles.button} float-right mb-2 mr-2`}
                      variant="secondary"
                      onClick={handleFormCancel}
              >
                Cancel
              </Button>
              <Button className={`${styles.button} float-right mb-2 mr-2`}
                      variant="primary"
                      onClick={handleFormSubmit}>
                Submit
              </Button>
            </React.Fragment>)
           : (
          <Button className={`${styles.button} float-right mb-2`}
                  variant="primary"
                  onClick={()=>setIsEdit(!isEdit)}
          >
            Edit
          </Button>)
        }
      </div>
    </form>
  );
}

export default AdditionalInfoTab;
