import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import InputBase from '@material-ui/core/InputBase';
// import SearchIcon from '@material-ui/icons/Search';
import companyLogo from '../../imgs/Company_Logo.png';
import { useStyles } from './NavbarStyles';

function SearchAppBar() {
  const classes = useStyles();

  return (
      <AppBar className={classes.root}>
        <Toolbar>
          <a href="https://www.jiayifuture.com">
            <img className={classes.logo} alt="Logo" src={companyLogo} />
          </a>
          {/*<div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>*/}
        </Toolbar>
      </AppBar>
  );
}

export default SearchAppBar;
