import React, { useState, useEffect } from "react";
import InputBase from '@material-ui/core/InputBase';
import Button from 'react-bootstrap/Button';
import { useStyles } from './AccountStyles';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Loading from '../utilsComponent/Loading';
import { API } from 'aws-amplify';
import { listReferees } from '../../graphql/queries';
import { createReferee,  deleteReferee } from '../../graphql/mutations';

function ReferralTab(props) {
  const styles = useStyles();
  const user = props.userInfo;
  const initialReferee = {
                            username: "",
                            refererId: user.id,
                            status: "pending"
                          }
  const [ referrerSubmit, setReferrerSubmit ] = useState(false);
  const [ referees, setReferees ] = useState([]);
  const [ referee, setReferee ] = useState(initialReferee);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    fetchUserReferees();
  }, [])

  const fetchUserReferees = async () => {
    try {
      const apiRes = await API.graphql({ query: listReferees });
      setReferees(apiRes.data.listReferees.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const handleReferrerSubmit = () => {
    setReferrerSubmit(!referrerSubmit);
    props.handleFormSubmit();
  }

  const isDisableReferrer = () => {
    // condition: 1. account creation less than 10 days
    //            2. referer has not been declared yet
    const today = new Date();
    const userCreationDate = new Date(user.createdAt);
    const dateDiff = (today - userCreationDate) / (1000*60*60*24);

    return (dateDiff > 10 || props.dbUser.referrer || referrerSubmit)? true : false;
  }

  const handleRefereeChange = (e) => {
    e.preventDefault();
    setReferee({...referee, username: e.target.value})
  }

  const handleRefereeSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const apiRes = await API.graphql({ query: createReferee,
                          variables: { input: referee}
                        });
      setReferees([...referees, apiRes.data.createReferee]);
      setReferee(initialReferee);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  const handleRefereeDelete = async(re) => {
    setLoading(true);
    try {
      const removeInput = {id: re.id}
      await API.graphql({ query: deleteReferee,
                          variables: { input: removeInput }});

      const newReferees = referees.filter(r => r.id !== re.id);
      setReferees(newReferees);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  return (
    <React.Fragment>
      <div className={styles.centerForm}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Referral Policy</Typography>
        </AccordionSummary>
        <AccordionDetails style={{flexDirection: 'column'}}>
          <Typography gutterBottom><i>1.</i> Within <strong>10 days</strong> of your account creation, you only have one chance to fill out your <strong>one and only</strong> referer. Please fill out your referrer within the due date!</Typography>
          <Typography gutterBottom><i>2.</i> For every person you refer and choose the jiayifuture's school application service, you'll win a <strong>$200</strong> Amazon gift card.</Typography>
          <Typography gutterBottom><i>3.</i> After you enter your referees, it goes to verification process. Once your referee enter your username
                as his/her referrer, you will be contacted within 5 business days and received the prize.
          </Typography>
          <Typography gutterBottom>* All right reserved by 佳一未来
          </Typography>
        </AccordionDetails>
      </Accordion>
      </div>
      <form className={styles.centerForm}>
        <div className="form-group row">
            <label htmlFor="oldPassword" className="col-md-3 col-form-label"><strong>Referrer</strong></label>
            <div className="col-md-7">
                <InputBase className="form-control"
                       name="referrer"
                       placeholder="Username"
                       value={user.referrer}
                       disabled={isDisableReferrer()}
                       onChange={props.handleFormChange}
                />
            </div>
            <div className="col-md-2">
              <Button
                      variant="primary"
                      onClick={handleReferrerSubmit}
                      disabled={isDisableReferrer()}
              >
                Submit
              </Button>
            </div>
        </div>
        <hr />
        <div className="form-group row">
            <label htmlFor="oldPassword" className="col-md-3 col-form-label"><strong>Referee</strong></label>
            <div className="col-md-7">
                <InputBase className="form-control"
                       required={true}
                       name="referee"
                       value={referee.username}
                       onChange={handleRefereeChange}
                       placeholder="Username"

                />
            </div>
            <div className="col-md-2">
              <Button
                      variant="primary"
                      type="submit"
                      onClick={handleRefereeSubmit}
              >
                Submit
              </Button>
            </div>
        </div>
      </form>

      <div className={styles.centerForm}>
        <div className="row">
          <p className="col-md-3">Waiting Verification: </p>
          <div className="col-md-9 mb-2">
            {
              referees.map((re) => (re.status === "pending")? (
                    <Chip
                      key={re.username}
                      variant="outlined"
                      size="small"
                      icon={<FaceIcon />}
                      label={re.username}
                      onDelete={() => handleRefereeDelete(re)}
                      color="secondary"
                      style={{margin: "0 5px 5px 5px"}}
                    />
                  ) : null
              )
            }

          </div>
        </div>

        <div className="row">
          <p className="col-md-3">Referred: </p>
          <div className="col-md-9">
          {
            referees.map((re) => (re.status === "pass")? (
                  <Chip
                    key={re.username}
                    variant="outlined"
                    size="small"
                    icon={<FaceIcon />}
                    label={re.username}
                    color="primary"
                    style={{margin: "0 5px 5px 5px"}}
                  />
                ) : null
              )
          }
          </div>
        </div>
      </div>

      <Loading open={loading} />
    </React.Fragment>
  )
}

export default ReferralTab;
