import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import "./styles/App.css";
import { withAuthenticator } from '@aws-amplify/ui-react';

function App() {
  return (
    <>
      <CssBaseline />
      <Navbar />
      <Sidebar />
    </>
  );
}

export default withAuthenticator(App);
