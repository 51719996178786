/*
// TODO: error handler for api
*/

import React, { useState, useEffect } from 'react';
import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid'
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Alert from '@material-ui/lab/Alert';
import DetailPanel from '../TableDetailPanel/DetailPanel';
import { API } from 'aws-amplify';
import { listSchools } from '../../graphql/queries';
import { createSchool, deleteSchool, updateSchool } from '../../graphql/mutations';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function Dashboard() {
  const columns = [
    {title: "id", field: "id", hidden: true},
    {title: "School", field: "name"},
    {title: "Program", field: "program"},
    {title: "Due", field: "due"},
    {title: "Account", field: "account"},
    {title: "Status", field: "status"},
  ]
  const initialSchoolForm = {
    name: "",
    program: "",
    due: "",
    account: "",
    status: ""
  }
  const [schools, setSchools] = useState([]); //table data
  const [formData, setFormData] = useState(initialSchoolForm);
  const [errorMessages, setErrorMessages] = useState([]) //for error handling
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    fetchSchools();
  }, [])

  const fetchSchools = async () => {
    try {
      const apiData = await API.graphql({ query: listSchools });
      setSchools(apiData.data.listSchools.items);
      setDataFetched(true);
    } catch (e) {
      setErrorMessages(["Server error! Please try again."]);
    }
  }

  const handleRowUpdate = async (newData, oldData, resolve, reject) => {
    //validation
    let errorList = [];

    if (newData.name === undefined){
      errorList.push("Please enter valid name");
    }

    if ( errorList.length > 0 ) {
      setErrorMessages(errorList)
      reject()
      return
    }

    try {
      const { updatedAt, createdAt, owner, tableData, ...rowData } = newData;
      await API.graphql({ query: updateSchool,
                          variables: { input: rowData }});
      const dataUpdate = [...schools];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setSchools([...dataUpdate]);
      setErrorMessages([]);
      resolve();
    } catch (e) {
      setErrorMessages(["Server error! Update failed."])
      reject(e);
    }
  }

  const handleRowAdd = async (newData, resolve) => {
    //validation
    let errorList = []
    if (newData.name === undefined){
      errorList.push("Please enter valid name")
    }
    if (schools.filter(school => school.name === newData.name).length > 0){
      errorList.push("The schools is already in the list")
    }

    if (errorList.length > 0) {
      setErrorMessages(errorList);
      resolve();
      return;
    }

    try {
      const apiSchool = Object.assign(formData, newData);
      const apiRes = await API.graphql({ query: createSchool,
                          variables: { input: apiSchool }
                        });
      setSchools([ ...schools, apiRes.data.createSchool ]);
      setFormData(initialSchoolForm);
      setErrorMessages([]);
      resolve()
    } catch (e) {
      setErrorMessages(["Server error! Cannot add data."])
      resolve()
    }
  }

  const handleRowDelete = async (id, resolve) => {
    try {
      await API.graphql({ query: deleteSchool,
                          variables: { input: { id } }});
      const newSchoolsArray = schools.filter(school => school.id !== id);
      setSchools(newSchoolsArray);
      setErrorMessages([]);
      resolve()
    } catch (e) {
      setErrorMessages(["Delete failed! Server error"])
      resolve()
    }
  }


  return (
      <Grid item>
          <div>
            {errorMessages.length > 0 &&
              <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                      return <div key={i}>{msg}</div>
                  })}
              </Alert>
            }
          </div>
          <MaterialTable
            title="Application Schools"
            columns={columns}
            data={schools}
            icons={tableIcons}
            options={{
                      headerStyle: {
                        fontWeight: "bold",
                        fontSize: 16,
                      },
                      actionsColumnIndex: -1,
                      paging:false,
                      minBodyHeight: "450px",
                      maxBodyHeight: "480px"
                    }}
            isLoading={!dataFetched}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    handleRowUpdate(newData, oldData, resolve, reject);
                }),
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  handleRowAdd(newData, resolve);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  handleRowDelete(oldData.id, resolve)
                }),
            }}
            detailPanel={ rowData => <DetailPanel rowData={rowData} handleRowUpdate={handleRowUpdate} />}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
      </Grid>
  );
}

export default Dashboard;
