import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "150px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  centerForm: {
    margin: "25px auto",
    width: 600,
  },
  input: {
    '&:disabled': {
      background: 'white',
      color: '#cccccc'
    },
    color: '#a6a6a6'
  }
}));
