import React, { useState, useEffect } from 'react';
import MaterialTable from "material-table";
import DetailPanel from '../TableDetailPanel/DetailPanel';
import RecommendationDialog from './RecommendationDialog';
import Loading from '../utilsComponent/Loading';
import { listRecommenders } from '../../graphql/queries';
import { createRecommender, updateRecommender, deleteRecommender } from '../../graphql/mutations';
import { API } from 'aws-amplify';

const initialRecommender = {
                            id: "",
                            name: "",
                            email: "",
                            phone: "",
                            company: "",
                            position: "",
                            address: "",
                            description: ""
                          }
function Recommendation() {
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ recommenders, setRecommenders ] = useState([]);
  const [ recommender, setRecommender ] = useState(initialRecommender);
  const [ loading, setLoading ] = useState(false);
  const [ isEdit, setIsEdit ] = useState(false);

  const tableColumns = [
    {title: "id", field: "id", hidden: true},
    {title: "Name", field: "name"},
    {title: "Email", field: "email"},
    {title: "Phone", field: "phone"},
    {title: "Company/School", field: "company"},
    {title: "Position", field: "position"},
    {title: "Address", field: "address"},
  ]

  useEffect(() => {
    fetchRecommenders();
  }, [])

  const fetchRecommenders = async () => {
    setLoading(true);
    try {
      const res = await API.graphql({ query: listRecommenders });
      setRecommenders(res.data.listRecommenders.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const handleAdd = () => {
    setRecommender(initialRecommender);
    setDialogOpen(!dialogOpen);
  }

  const handleEdit = (data) => {
    setRecommender(data);
    setDialogOpen(!dialogOpen);
    setIsEdit(true);
  }

  const handleSubmit = async(data) => {
    setLoading(true);
    setDialogOpen(false);
    try {
      if (!isEdit){
        const apiRes = await API.graphql({ query: createRecommender,
                            variables: { input: data}
                          });
        setRecommenders([...recommenders, apiRes.data.createRecommender]);
      } else {
        handleRowUpdate(data)
      }
    } catch (e) {
      console.log(e);
    }
    setRecommender(initialRecommender);
    setLoading(false);
    setIsEdit(false);
  }

  const handleRowUpdate = async (newData, oldData, resolve, reject) => {
    try {
      const { updatedAt, createdAt, owner, tableData, ...recommenderData } = newData;
      await API.graphql({ query: updateRecommender,
                          variables: { input: recommenderData }});
      const dataUpdate = [...recommenders];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setRecommenders(dataUpdate);
      setIsEdit(false);
      resolve();
  } catch (e) {
    reject(e);
  }
  }

  const handleRowDelete = async (id, resolve) => {
    try {
      await API.graphql({ query: deleteRecommender,
                          variables: { input: { id } }});

      const newRecommenders = recommenders.filter(re => re.id !== id);
      setRecommenders(newRecommenders);
      resolve()
    } catch (e) {
      resolve()
    }
  }

  return (
    <>
      <MaterialTable
        title="Recommenders"
        columns={tableColumns}
        data={recommenders}
        options={{
          headerStyle: {
            fontWeight: "bold",
            fontSize: 16,
            position: 'sticky',
            top: 0
          },
          actionsColumnIndex: -1,
          paging: false,
          minBodyHeight: "450px",
          maxBodyHeight: "480px"
        }}
        isLoading={loading}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData.id, resolve)
            }),
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (event, rowData) => handleEdit(rowData)
          },
          {
            icon: 'add',
            tooltip: 'Add',
            isFreeAction: true,
            onClick: (event) => handleAdd()
          }
        ]}
        detailPanel={ rowData => <DetailPanel rowData={rowData} handleRowUpdate={handleRowUpdate} />}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
      <RecommendationDialog open={dialogOpen}
                            modifyOpenState={handleAdd}
                            recommender={recommender}
                            handleSubmit={handleSubmit}
      />
      <Loading open={loading} />
    </>
  )
}

export default Recommendation;
