import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  dialogContent: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  dropzone: {
    color: '#b3b3b3',
  },
  dropzoneIcon: {
    fontSize: 50
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
