import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { fileCategories } from './FileUploadCategories';

export const getTableColumns = (schools) => {
  const tableColumns = [
    { title: 'Name', field: 'name', editable: 'never'},
    { title: 'Created', field: 'createdAt', editable: 'never'},
    { title: 'School',
      field: 'school',
      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
        const value = p.value;
        return (<TextField
          select
          value={value}
          onChange={(event) => {
            const chosenSchool = event.target.value;
            const newRowData = { ...rowData, school: chosenSchool, program: ""};
            onRowDataChange(newRowData);
          }}
        >
          {schools.map((school) => (
            <MenuItem key={school.name}
                      value={school.name}>
              {school.name}
            </MenuItem>
          ))}
        </TextField>);}
    },
    { title: 'Program',
      field: 'program',
      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
        const schoolPrograms = schools.map((school) => school.name === rowData.school? school.program : "");
        const programs = [...new Set(schoolPrograms)];
        const value = p.value;

        return (<TextField
          select
          value={value}
          onChange={(event) => {
            const newRowData = { ...rowData, program: event.target.value};
            onRowDataChange(newRowData);
          }}
        >
          {programs.map((program) => (
            <MenuItem key={program}
                      value={program}>
              {program}
            </MenuItem>
          ))}
        </TextField>);}
    },
    { title: 'Category',
      field: 'category',
      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
        const value = p.value;

        return (<TextField
          select
          value={value}
          onChange={(event) => {
            const newRowData = { ...rowData, category: event.target.value};
            onRowDataChange(newRowData);
          }}
        >
          {fileCategories.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>);}

    },
  ];
  return tableColumns;
}
