/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSchool = /* GraphQL */ `
  mutation CreateSchool(
    $input: CreateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    createSchool(input: $input, condition: $condition) {
      id
      name
      program
      due
      account
      status
      description
      calendarColor
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool(
    $input: UpdateSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    updateSchool(input: $input, condition: $condition) {
      id
      name
      program
      due
      account
      status
      description
      calendarColor
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool(
    $input: DeleteSchoolInput!
    $condition: ModelSchoolConditionInput
  ) {
    deleteSchool(input: $input, condition: $condition) {
      id
      name
      program
      due
      account
      status
      description
      calendarColor
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCalendarEvent = /* GraphQL */ `
  mutation CreateCalendarEvent(
    $input: CreateCalendarEventInput!
    $condition: ModelCalendarEventConditionInput
  ) {
    createCalendarEvent(input: $input, condition: $condition) {
      id
      text
      startDate
      endDate
      typeId
      description
      recurrenceRule
      recurrenceException
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCalendarEvent = /* GraphQL */ `
  mutation UpdateCalendarEvent(
    $input: UpdateCalendarEventInput!
    $condition: ModelCalendarEventConditionInput
  ) {
    updateCalendarEvent(input: $input, condition: $condition) {
      id
      text
      startDate
      endDate
      typeId
      description
      recurrenceRule
      recurrenceException
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCalendarEvent = /* GraphQL */ `
  mutation DeleteCalendarEvent(
    $input: DeleteCalendarEventInput!
    $condition: ModelCalendarEventConditionInput
  ) {
    deleteCalendarEvent(input: $input, condition: $condition) {
      id
      text
      startDate
      endDate
      typeId
      description
      recurrenceRule
      recurrenceException
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      name
      size
      item
      school
      program
      category
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      name
      size
      item
      school
      program
      category
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      name
      size
      item
      school
      program
      category
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      birthDate
      phone
      address1
      address2
      city
      state
      zip
      description
      goal
      reasonToStudyGraduateSchool
      majorApplying
      plan
      referrer
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      birthDate
      phone
      address1
      address2
      city
      state
      zip
      description
      goal
      reasonToStudyGraduateSchool
      majorApplying
      plan
      referrer
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      gender
      birthDate
      phone
      address1
      address2
      city
      state
      zip
      description
      goal
      reasonToStudyGraduateSchool
      majorApplying
      plan
      referrer
      createdAt
      updatedAt
    }
  }
`;
export const createEducation = /* GraphQL */ `
  mutation CreateEducation(
    $input: CreateEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    createEducation(input: $input, condition: $condition) {
      id
      school
      degree
      major
      gpa
      gpaScale
      startDate
      endDate
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEducation = /* GraphQL */ `
  mutation UpdateEducation(
    $input: UpdateEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    updateEducation(input: $input, condition: $condition) {
      id
      school
      degree
      major
      gpa
      gpaScale
      startDate
      endDate
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEducation = /* GraphQL */ `
  mutation DeleteEducation(
    $input: DeleteEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    deleteEducation(input: $input, condition: $condition) {
      id
      school
      degree
      major
      gpa
      gpaScale
      startDate
      endDate
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createExperience = /* GraphQL */ `
  mutation CreateExperience(
    $input: CreateExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    createExperience(input: $input, condition: $condition) {
      id
      title
      company
      employmentType
      location
      startDate
      endDate
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateExperience = /* GraphQL */ `
  mutation UpdateExperience(
    $input: UpdateExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    updateExperience(input: $input, condition: $condition) {
      id
      title
      company
      employmentType
      location
      startDate
      endDate
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteExperience = /* GraphQL */ `
  mutation DeleteExperience(
    $input: DeleteExperienceInput!
    $condition: ModelExperienceConditionInput
  ) {
    deleteExperience(input: $input, condition: $condition) {
      id
      title
      company
      employmentType
      location
      startDate
      endDate
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createReferee = /* GraphQL */ `
  mutation CreateReferee(
    $input: CreateRefereeInput!
    $condition: ModelRefereeConditionInput
  ) {
    createReferee(input: $input, condition: $condition) {
      id
      username
      refererId
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateReferee = /* GraphQL */ `
  mutation UpdateReferee(
    $input: UpdateRefereeInput!
    $condition: ModelRefereeConditionInput
  ) {
    updateReferee(input: $input, condition: $condition) {
      id
      username
      refererId
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteReferee = /* GraphQL */ `
  mutation DeleteReferee(
    $input: DeleteRefereeInput!
    $condition: ModelRefereeConditionInput
  ) {
    deleteReferee(input: $input, condition: $condition) {
      id
      username
      refererId
      status
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRecommender = /* GraphQL */ `
  mutation CreateRecommender(
    $input: CreateRecommenderInput!
    $condition: ModelRecommenderConditionInput
  ) {
    createRecommender(input: $input, condition: $condition) {
      id
      name
      email
      phone
      company
      position
      address
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRecommender = /* GraphQL */ `
  mutation UpdateRecommender(
    $input: UpdateRecommenderInput!
    $condition: ModelRecommenderConditionInput
  ) {
    updateRecommender(input: $input, condition: $condition) {
      id
      name
      email
      phone
      company
      position
      address
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRecommender = /* GraphQL */ `
  mutation DeleteRecommender(
    $input: DeleteRecommenderInput!
    $condition: ModelRecommenderConditionInput
  ) {
    deleteRecommender(input: $input, condition: $condition) {
      id
      name
      email
      phone
      company
      position
      address
      description
      createdAt
      updatedAt
      owner
    }
  }
`;
