import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { listSchools } from '../../graphql/queries';

function GetSchools() {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    fetchSchools();
  }, [])

  const fetchSchools = async () => {
    try {
      const apiData = await API.graphql({ query: listSchools });
      setSchools(apiData.data.listSchools.items);
    } catch (e) {
      console.log(e);
    }
  }

  return schools;
}

export default GetSchools;
