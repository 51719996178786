import { makeStyles } from '@material-ui/styles';

const styles = makeStyles((theme) => ({
  root: {
    padding: "30px 20px 0px 20px",
    color: "white",
  },
  divider: {
    background: "#808080",
  }
}));

export default styles;
